import jScrolly from './jScrolly.min';

var jScrollyDemo = new jScrolly({
  buttonNextClass: 'nextBtn'
});

// function toggleNav() {
//   var x = document.getElementById("main-nav");
//   // if (x.className === "nav-list") {
//   //   x.className += " responsive";
//   // } else {
//   //   x.className = "nav-list";
//   // }
//   if (x.style.visibility.indexOf('visible') === -1) {
//     x.style.visibility = 'visible';
//   } else {
//     x.style.visibility = 'hidden';
//   }
// }

// window.toggleNav = toggleNav;
